import React from "react"

import Layout from "../components/layout"

function ContactPage(props) {
  return (
    <Layout>
      <div className="container h-320 relative mb-108">
        <div className="hero-rect w-3/4 md:w-1/2 h-320 bg-blue-100 absolute left-0 top-0 bottom-0"></div>

        <div className="flex pt-32">
          <div className="hero-content w-4/6 md:ml-64 relative">
            <p className="text-black text-20 dash-right">Here to help</p>
            <h1 className="text-50 lg:text-100 xl:text-80">
              Thanks for <span className="text-gray-100">contacting us</span>
            </h1>
          </div>
        </div>

        <div className="flex flex-col md:flex-row relative z-10 mt-24 md:mx-64">
          <div className="md:w-1/2">
            <p>
              We have you message and one of the team members will get back to
              you when they can.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
